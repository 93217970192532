export const useToastificationHelper = () => {

  const findSearchTerms = (string, arrayOfSearchTerms) => {
    if (!arrayOfSearchTerms || arrayOfSearchTerms.length === 0) {
      return -1
    }
    for (let i = 0; i < arrayOfSearchTerms.length; i++) {
      if (string.indexOf(`{${arrayOfSearchTerms[i]}}`) > -1) {
        return i
      }
    }
    return -1
  }

  const translateAndFormat = (messageObject, entityName = null) => {
    const app = useNuxtApp()
    const allTranslations = app.$i18n?.messages?.value[app.$i18n.locale.value]

    // eslint-disable-next-line eqeqeq
    if (allTranslations == null) {
      return messageObject.messageKey
    }
    const rawElement = entityName ?
      allTranslations[entityName][messageObject.messageKey] :
      allTranslations[messageObject.messageKey]
    const translationStringArray = rawElement.split(/[\s]+/)

    const messageValues = messageObject?.messageValues
    if (!messageValues) {
      return rawElement
    }
    const messageValuesArray = Object.values(messageValues)

    return h('div', translationStringArray.map((ts) => {
      if (ts.match(/<br[/]*>/)) {
        return h('br')
      }
      const position = findSearchTerms(ts, Object.keys(messageValues))
      const currentValue = messageValuesArray[position]
      if (position > -1) {
        return [h('b', ts.replace(/{([^{}]+)}/, currentValue)), ' ']
      } else {
        return [ts, ' ']
      }
    }))
  }

  return {
    translateAndFormat
  }
}
